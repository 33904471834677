import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../../components/seo';
import Day from '../../components/weekdays/Day';

function MondayPage(data) {
  const day = 1;
  const dayColors = ['bg-faith-green', 'bg-faith-orange'];

  return (
    <>
      <SEO
        keywords={[
          `work-life balance`,
          `diary`,
          `lone parent`,
          `childcare`,
          'ireland',
        ]}
        title="Monday's Diary Entry"
      />
      <Day day={day} post={data} dayColors={dayColors} />
    </>
  );
}
export const query = graphql`
  {
    ani1: allFile(filter: { name: { eq: "mon_1" }, ext: { eq: ".json" } }) {
      edges {
        node {
          publicURL
        }
      }
    }

    ani2: allFile(filter: { name: { eq: "mon_2" }, ext: { eq: ".json" } }) {
      edges {
        node {
          publicURL
        }
      }
    }

    ani3: allFile(filter: { name: { eq: "mon_3" }, ext: { eq: ".json" } }) {
      edges {
        node {
          publicURL
        }
      }
    }

    ani4: allFile(filter: { name: { eq: "mon_4" }, ext: { eq: ".json" } }) {
      edges {
        node {
          publicURL
        }
      }
    }

    wpPage(slug: { eq: "monday" }) {
      id
      sectionDay {
        section1 {
          content
          header
          explainerBody
          explainerHeading
          explainerImage {
            localFile {
              publicURL
            }
          }
          illustration {
            localFile {
              publicURL
            }
          }
        }
        section2 {
          content
          header
          illustration {
            localFile {
              publicURL
            }
          }
        }
        section3 {
          content
          header
          explainerBody
          explainerHeading
          explainerImage {
            localFile {
              publicURL
            }
          }
          illustration {
            localFile {
              publicURL
            }
          }
        }
      }
      title
      content
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;
export default MondayPage;
